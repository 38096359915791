import React from "react";

const Guest = () => {
  //let param = "?source=miro&action=upgrade";
  return (
    <>
      <div className="d-inline">
        <span className="p-small mx-2">
          You are not logged in!
        </span>
      </div>
    </>
  );
};

export default Guest;
