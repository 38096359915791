import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { events } from "./api/statistics";
import App from "./App";
import { persistor, store } from "./redux/store";
//import { formatDate } from "./util/helper";

const { board } = window.miro;
let userInfo = null;

async function init() {
  board.ui.on("icon:click", async () => {
    await board.ui.openPanel({ url: "index.html" });
    userInfo = await board.getUserInfo();
    if (userInfo){
      localStorage.setItem('miroId', userInfo.id);
      events('app_open');
    }
  });

  

  //board.ui.on("drop", async ({ x, y, target }) => {
    /* if (target instanceof HTMLImageElement) {
      await board.createImage({ x, y, url: target.src });
      let postData = {
        "app": "miro",
        "app_user_id": localStorage.getItem('miroId'),
        "asset_id": target.getAttribute('data-image-id'),
        "asset_type": target.getAttribute('data-image-type'),
        "datetime": formatDate(new Date())
      }
      usage(postData);
    } */
 // });
}

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

init();