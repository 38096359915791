import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequest } from "../../api/request";

export const fetchAsyncProjectScenes = createAsyncThunk(
	"projectScenes/fetchAsyncProjectScenes",
	async (postData) => {
		let param = `/projects/${postData.projectId}?segment=${postData.segment}&apiToken=${postData.apiToken}`;
		const response = await ApiRequest.get(param);
		return response.data;
	}
);

const projectScenesSlice = createSlice({
	name: "projectScenes",
	initialState: {
		images: [],
		segment: 0,
		searched_text: "gmelkgnrel",
		project_name: undefined,
		no_of_scenes: undefined,
		loading: false,
		paginate: true,
		notfound: false,
	},
	reducers: {
		projectResultReset: (state) => {
			state.images = [];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAsyncProjectScenes.pending, (state) => {
			//console.log("fetchAsyncProjectScenes Pending");
			state.loading = true;
		});
		builder.addCase(fetchAsyncProjectScenes.fulfilled, (state, action) => {
			//console.log("fetchAsyncProjectScenes Successfully");

			state.images.push(...action.payload.data?.projects);
			state.segment = action.payload.data?.segment;

			state.project_name = action.payload.data?.project_name;
			state.no_of_scenes = action.payload.data?.no_of_scenes;

			state.searched_text = action.payload.data?.searched_text;
			state.loading = false;
			state.paginate = action.payload.data?.paginate;
			state.notfound =
				action.payload.data?.projects?.length === 0 ? true : false;
		});
		builder.addCase(fetchAsyncProjectScenes.rejected, (state, action) => {
			//console.log("fetchAsyncProjectScenes Rejected");
			state.loading = false;
		});
	},
});

export const { projectResultReset } = projectScenesSlice.actions;

export const getProjectScenes = (state) => state.project_scenes.images;
export const getProjectName = (state) => state.project_scenes.project_name;
export const getScenesCount = (state) => state.project_scenes.no_of_scenes;
export const getSegmant = (state) => state.project_scenes.segment;
export const getSearchedText = (state) => state?.project_scenes.searched_text;
export const getLoading = (state) => state.project_scenes.loading;
export const getPaginate = (state) => state.project_scenes.paginate;
export const getNotFound = (state) => state.project_scenes.notfound;

export default projectScenesSlice.reducer;
