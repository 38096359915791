import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchAsyncProjects,
	projectResultReset,
} from "../../redux/reducers/projectsReducer";
import "./style.scss";
import { Config } from "../../util/config";
import { getCurentUser } from "../../redux/reducers/userReducer";
import { useLocation } from "react-router-dom";

const SearchProjects = () => {
	const oUser = useSelector(getCurentUser);
	const [searchString, setSearchString] = useState("");
	const dispatch = useDispatch();

	const location = useLocation();
	const { pathname } = location;
	const to = pathname.split("/")[1];
	
	useEffect(() => {
		setSearchString(searchString);
	}, [searchString]);

	useEffect(() => {
		let postData = {
			segment: 0,
			apiToken: oUser.apiToken,
			searched_text: "all",
			type: Config.projects["ext"],
		};

		dispatch(projectResultReset());
		dispatch(fetchAsyncProjects(postData));
	}, [dispatch, oUser.apiToken]);

	const handleSearch = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			let postData = {
				segment: 0,
				searched_text: searchString,
				type: Config.projects["ext"],
			};
			dispatch(fetchAsyncProjects(postData));
			dispatch(projectResultReset());
		}
	};

	return (
		<>
			<div
				className={`${to === "projects" ? "projects" : ""} search-container`}
			>
				<div className="form-group">
					<input
						className="input"
						type="text"
						placeholder="Search projects"
						onChange={(e) => {
							setSearchString(e.target.value);
						}}
						onKeyDown={(e) => handleSearch(e)}
						value={searchString}
					/>
				</div>
			</div>
		</>
	);
};

export default SearchProjects;
