import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequest } from "../../api/request";

export const fetchAsyncImages = createAsyncThunk(
  "images/fetchAsyncImages",
  async (postData) => {
    let param = `/search?segment=${postData.segment}&searched_text=${
      postData.searched_text === "" ? "all" : postData.searched_text
    }&type=${postData.type}`;
    const response = await ApiRequest.get(param);
    return response.data;
  }
);

const imagesSlice = createSlice({
  name: "images",
  initialState: {
    images: [],
    segment: 0,
    searched_text: "all",
    loading: false,
    paginate: true,
    notfound: false,
  },
  reducers: {
    searchImagesReset: (state) => {
      state.images = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncImages.pending, (state) => {
      //console.log("fetchAsyncImages Pending");
      state.loading = true;
    });
    builder.addCase(fetchAsyncImages.fulfilled, (state, action) => {
      //console.log("fetchAsyncImages Successfully");
      state.loading = false;
      state.notfound = action.payload.data?.images?.length === 0 ? true : false;
      if( action.payload.data?.images?.length > 0){
        state.images.push(...action.payload.data?.images);
      }
      state.segment = action.payload.data?.segment;
      state.searched_text = action.payload.data?.searched_text;
      state.paginate = action.payload.data?.paginate;
      
    });
    builder.addCase(fetchAsyncImages.rejected, (state, action) => {
      //console.log("fetchAsyncImages Rejected");
      state.loading = false;
    });
  },
});

export const { searchImagesReset } = imagesSlice.actions;

export const getAllImages = (state) => state?.images.images;
export const getSegmant = (state) => state?.images?.segment;
export const getSearchedText = (state) => state?.images.searched_text;
export const getLoading = (state) => state?.images?.loading;
export const getPaginate = (state) => state?.images?.paginate;
export const getNotFound = (state) => state?.images?.notfound;

export default imagesSlice.reducer;
