import React from "react";
import { useSelector } from "react-redux";
import {
	getProjectScenes,
	getProjectName,
	getScenesCount,
} from "../../redux/reducers/projectScenesReducer";
import { Link } from "react-router-dom";
import "./styles.scss";
import ProjectImage from "../ProjectImage";

const ProjectScenesList = (props) => {
	const projectName = useSelector(getProjectName);
	const scenesCount = useSelector(getScenesCount);
	const aScenes = useSelector(getProjectScenes);

	return (
		<>
			<div className="header">
				<div className="text-center">
					<Link
						to="/projects"
						className="button-icon menu-button icon-left-arrow"
						type="button"
					></Link>
				</div>
				<div>
					<div>{projectName}</div>
					<div>{scenesCount} pages</div>
				</div>
			</div>

			<div className="search-results-container">
				<div className="overflow-container project-results-container">
					<div className="grid">
						{aScenes?.map((image, index) => (
							<ProjectImage key={index} index={index + 1} image={image} />
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default ProjectScenesList;
