import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import "./style.scss";

const Failed = () => {
  return (
    <div className="app-container request-form-failed auto-height">
      <h4 className="h4 title-h4">Image request failed!</h4>

      <div className="image-place-holder banner-images">
        <LazyLoadImage
          src="/images/request-failed.jpg"
          className="img-center"
          height="208"
          width="308"
          alt=""
        />
      </div>

      <p className="p-large text-center">
        Bad news! Unfortunately the image request you just sent has failed to
        reach us. Can you please try sending us an image request once again?
      </p>

      <p className="p-large text-center">
        Can you please try sending us an image request once again?
      </p>

      <Link to="/request" className="w-100 button button-primary">
        Try again
      </Link>
    </div>
  );
};

export default Failed;
