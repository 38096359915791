import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./style.scss";

const Empty = () => {
  return (
    <div className="empty-viwe-container app-container auto-height">
      <h4 className="h4 title-h4">No projects to display</h4>
      <div className="image-place-holder banner-images">
        <LazyLoadImage
          src="/images/no-projects.jpg"
          className="img-center"
          height="205"
          width="308"
          alt=""
        />
      </div>
      <a
        className="text-center w-100 button button-primary"
        href={`${process.env.REACT_APP_DRAWIFY_DOMAIN}/customer/dashboard`}
        target="_blank"
        rel="noreferrer"
      >
        Create a new project
      </a>
    </div>
  );
};

export default Empty;
