import axios from "axios";
import { store } from "../redux/store";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const APP_AUTH_KEY = process.env.REACT_APP_AUTH_KEY;

export const publicRequest = axios.create({
    baseURL: BASE_URL,
    headers: { 
        Accept: 'application/json',
        AppAuthKey : APP_AUTH_KEY,
    },
});

export const privateRequest = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'application/json',
        AppAuthKey : APP_AUTH_KEY,
    },
});

privateRequest.interceptors.request.use(
    config => {
        const state = store.getState();
        const TOKEN = state?.user?.currentUser?.accessToken;
        if(TOKEN) {
            config.headers['Authorization'] = 'Bearer ' + TOKEN
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
  );

