import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthMenu from "../../../../components/AuthMenu";
import { getCurentUser } from "../../../../redux/reducers/userReducer";
import "./style.scss";

const Step2 = () => {
  const user = useSelector(getCurentUser);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  },[user,navigate]); 

  return (
    <div className="app-container auto-height">
      <h4 className="h4 title-h4">
        Power up your team's collaboration with Drawify
      </h4>
      <img src="/images/onboarding-step-2.jpg" alt="" className="img-center" />
      <p className="p-description">
        Create engaging boards with a human touch by using hand-drawn images
        from Drawify in Miro
      </p>
      <ul className="login-steps">
        <li>
          <Link className="next circle" to="/login/step-1"></Link>
        </li>
        <li>
          <Link className="next circle active" href="/login/step-2"></Link>
        </li>
        <li>
          <Link className="next circle" to="/login"></Link>
        </li>
      </ul>
      <AuthMenu/>
    </div>
  );
};

export default Step2;
