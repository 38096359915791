import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./style.scss";

const Empty = () => {
  return (
    <div className="empty-viwe-container app-container auto-height">
      <h4 className="h4 title-h4">No favorites to display</h4>
      <div className="image-place-holder banner-images">
        <LazyLoadImage
          src="/images/no-favorites.jpg"
          className="img-center"
          height="205"
          width="308"
          alt=""
        />
      </div>
      <p className="p-large text-center">
        Save any image to your favorites by clicking on the heart icon
      </p>
    </div>
  );
};

export default Empty;
