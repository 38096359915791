import React from "react";
import './style.scss';

const Spinner = ({show}) => {
  return (
    <div id="overlay-spinner" className={` ${show ? 'show' : ''}`}>
      <div className={`spinner`}>
        <img src="images/spinner.svg" alt="Spinner" />
      </div>
    </div>
  );
};

export default Spinner;
