import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequest } from "../../api/request";

export const postAsyncRequest = createAsyncThunk(
  "uploads/postAsyncRequest",
  async (postData) => {
    // TODO : ApiRequest | privateRequest |  should be handle based on User
    const response = await ApiRequest.post("/request", postData);
    return response.data;
  }
);

const requestSlice = createSlice({
  name: "request",
  initialState: {
    loading: false,
    submited: undefined,
    message: "",
  },
  reducers: {
    submitSuccess: (state) => {
      state.loading = false;
      state.submited = undefined;
      state.message = "";
    },
    submitFailed: (state) => {
      state.loading = false;
      state.submited = undefined;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(postAsyncRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postAsyncRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.submited = action.payload.status;
      state.message = action.payload.message;
    });
    builder.addCase(postAsyncRequest.rejected, (state, action) => {
      state.loading = false;
      state.message = "Something went wrong";
    });
  },
});

export const { submitSuccess, submitFailed } = requestSlice.actions;

export const getLoading = (state) => state.request.loading;
export const getSubmitted = (state) => state.request.submited;
export const getMessage = (state) => state.request.message;

export default requestSlice.reducer;
