import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import RequestImageMenu from "../../../components/RequestImageMenu";

const OnlyForHero = () => {
  return (
    <>
      <h4 className="h4 title-h4">There are no images found on Miro</h4>
      <div className="image-place-holder banner-images">
        <LazyLoadImage
          src="/images/no-images.jpg"
          className="img-center"
          height="205"
          width="308"
          alt=""
        />
      </div>
      <p className="p-description">
        There are no images found on Drawify for your search term. Please send
        your request
      </p>
      <RequestImageMenu />
    </>
  );
};

export default OnlyForHero;
