import React from "react";
import "./style.scss";

function NewProjectBtn() {
  return (
    <>
    <a
      className="create-project"
      href={`${process.env.REACT_APP_DRAWIFY_DOMAIN}/customer/dashboard?src=miro`}
      target="_blank"
      rel="noreferrer"
    >
      <span className="icon icon-add"></span>
    </a>
    </>
  );
}

export default NewProjectBtn;