import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequest } from "../../api/request";

export const postAsyncFavoriteRemove = createAsyncThunk(
  "favoritesRemove/postAsyncFavoriteRemove",
  async (postData) => {
    const response = await ApiRequest.post("/favorite/toggle",postData);
    return response.data;
  }
);

const favoriteAddSlice = createSlice({
  name: "favoritesRemove",
  initialState: {
    loading: false,
    status: undefined,
    message: "",
  },
  reducers: {
    removeFromFaroviteList: (state) => {
      state.loading = false;
      state.status = undefined;
      state.message = "";
    },
    submitFailed: (state) => {
      state.loading = false;
      state.status = undefined;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postAsyncFavoriteRemove.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postAsyncFavoriteRemove.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
    });
    builder.addCase(postAsyncFavoriteRemove.rejected, (state, action) => {
      state.loading = false;
    });
  },
});


export const getStatus = (state) => state.favoritesRemove?.status;
export const getLoading = (state) => state.favoritesRemove?.loading;
export const getMessage = (state) => state.favoritesRemove?.message;

export default favoriteAddSlice.reducer;
