import { useSelector } from "react-redux";
import ImagesList from "../../components/ImagesList";
import SearchImages from "../../components/SearchInput/SearchImages";
import Spinner from "../../components/Spinner";
import { getLoading, getNotFound } from "../../redux/reducers/imagesReducer";
import NotFound from "./NotFound";
import "./style.scss";

const Home = () => {
  const isNotFound = useSelector(getNotFound);
  const isLoadings = useSelector(getLoading);

  return (
    <>
      <SearchImages />
      {!isNotFound ? <ImagesList /> : <NotFound />}
      <Spinner show={isLoadings} />
    </>
  );
};

export default Home;
