import React from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import FavoritesLists from "../../components/FavoritesList";
import Spinner from "../../components/Spinner";
import { getLoading, getNotFound } from "../../redux/reducers/favoritesReducer";
import Empty from "./Empty";
import Guest from "./Guest";
import { getCurentUser } from "../../redux/reducers/userReducer";
import SearchFavorites from "../../components/SearchInput/SearchFavorites";

const Favorites = () => {
  const isNotFound = useSelector(getNotFound);
  const isLoadings = useSelector(getLoading);
  const user = useSelector(getCurentUser);
  
  return (
    <>
      {user && <SearchFavorites />}
      {user ? (!isNotFound  ? (<FavoritesLists />) : (<Empty/>)) : (<Guest />)}
      <Spinner show={isLoadings} />
    </>
  );
};

export default Favorites;
