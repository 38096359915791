import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const AuthMenu = () => {

  return (
    <div className="auth-guest-menu">
      <Link
        to="/login"
        type="button"
        className="w-100 button button-primary"
      >
        Sign in
      </Link>
      <p className="seperator uppercase">or</p>
      <a
        type="button"
        className="w-100 button button-secondary"
        rel="noreferrer"
        href={`${process.env.REACT_APP_USER_SIGN_UP_URL}?source=miro`}
        target="_blank"
      >
        Sign up
      </a>
    </div>
  );
};

export default AuthMenu;
