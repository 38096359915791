import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchAsyncImages,
  searchImagesReset,
} from "../../redux/reducers/imagesReducer";
import "./style.scss";
import { Config } from "../../util/config";

const SearchImages = () => {
  const [searchString, setSearchString] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setSearchString(searchString);
  }, [searchString]);

  useEffect(() => {
    let postData = {
      segment: 0,
      searched_text: "all",
      type: Config.images['ext'],
    };

    dispatch(searchImagesReset());
    dispatch(fetchAsyncImages(postData));
  }, [dispatch]);

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      let postData = {
        segment: 0,
        searched_text: searchString,
        type: Config.images['ext'],
      };
      dispatch(fetchAsyncImages(postData));
      dispatch(searchImagesReset());
    }
  };

  return (
    <>
      <div className="search-container images">
        <div className="form-group">
          <input
            className="input"
            type="text"
            placeholder="Search images"
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            onKeyDown={(e) => handleSearch(e)}
            value={searchString}
          />
        </div>
      </div>
    </>
  );
};

export default SearchImages;
