import axios from "axios";
import { store } from "../redux/store";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const APP_AUTH_KEY = process.env.REACT_APP_AUTH_KEY;

/* const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.accessToken; */

let headers = {
	Accept: "application/json",
	AppAuthKey: APP_AUTH_KEY,
};

/* export const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    AppAuthKey: APP_AUTH_KEY,
  },
});
 */


/* if (currentUser) {
  headers["Authorization"] = "Bearer " + TOKEN;
} */


/* export const privateRequest = axios.create({
  baseURL: BASE_URL,
  headers: headers,
  Authorization : "Bearer " + TOKEN
}); */

export const ApiRequest = axios.create({
	baseURL: BASE_URL,
	headers: headers,
});

ApiRequest.interceptors.request.use(
  config => {
      const state = store.getState();
      const TOKEN = state?.user?.currentUser?.accessToken;
      if(TOKEN) {
          config.headers['Authorization'] = 'Bearer ' + TOKEN
      }
      return config;
  },
  error => {
      Promise.reject(error);
  }
);
