import React from "react";
import { useSelector } from "react-redux";
import ImageRequest from "../../components/ImageRequest";
import { getCurentUser } from "../../redux/reducers/userReducer";
import Guest from "./Guest";
import Pro from "./Pro";
import "./style.scss";

const Request = () => {
  const user = useSelector(getCurentUser);
  const sPlan = user?.userPlan;
  return <>{user ? (sPlan === "Hero" ? <ImageRequest /> : <Pro/>) : <Guest />}</>;
};

export default Request;
