import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import "./style.scss";

const Success = () => {
  return (
    <div className="app-container request-form-sucess auto-height">
      <h4 className="h4 title-h4">Image request success!</h4>
      <div className="image-place-holder banner-images">
        <LazyLoadImage
          src="/images/request-sucess.jpg"
          className="img-center"
          height="208"
          width="308"
          alt=""
        />
      </div>

      <p className="p-large text-center">
        Thanks for sending us your request. We cant wait to get started on
        drawing it!
      </p>

      <p className="p-large text-center">
        You will find your new images available within 72 hours.
      </p>

      <Link to="/request" className="w-100 button button-primary">
        Send another request
      </Link>
    </div>
  );
};

export default Success;
