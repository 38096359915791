import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  getLoading,
  postAsyncRequest,
  submitFailed,
  submitSuccess,
} from "../../redux/reducers/requestReducer";
import { useNavigate } from "react-router-dom";

const ImageRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(getLoading);

  const [requestMessage, setRequestMessage] = useState("");
  const [isFormError, setFormError] = useState(false);

  const handleSubmit = () => {
    if (requestMessage) {
      let postData = {
        message: requestMessage,
        miro_user_id: localStorage.getItem("miroId"),
      };

      dispatch(postAsyncRequest(postData)).then((res)=>{
        if(res.payload.status){
          toast.success(res.payload.message);
          dispatch(submitSuccess());
          navigate("/request/success");
        } else{
          toast.error(res.payload.message);
          dispatch(submitFailed());
          navigate("/request/failed");
        }
      });

    } else {
      setFormError(true);
    }
  };

  return (
    <>
      <div className="app-container request-form auto-height">
        <h4 className="h4 title-h4">Request an image</h4>
        <form>
          <div className={`${isFormError ? "error" : ""} form-group`}>
            <textarea
              className="input"
              placeholder="Describe the image you would like"
              spellCheck="true"
              name="message"
              onChange={(e) => setRequestMessage(e.target.value)}
            ></textarea>
            {isFormError && <div className="status-text">Cannot be empty</div>}
          </div>
        </form>
        <button
          type="button"
          className={`${
            isLoading ? "button-loading" : ""
          } w-100 button button-primary `}
          onClick={handleSubmit}
        >
          Send image request
        </button>
      </div>
    </>
  );
};

export default ImageRequest;
