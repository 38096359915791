import React from "react";
import { events } from "../../../api/statistics";

const Pro = () => {

  let param = "?source=miro&action=upgrade";

  const handleUpgrade = () => {
    events('pro_clicked_upgrade');
  }

  return (
    <>
      <span className="p-small">
        Upgrade to
        <a
          rel="noreferrer"
          href={`${process.env.REACT_APP_USER_UPGRADE_TO_HERO_URL}${param}`}
          className="link link-text"
          target="_blank"
          onClick={handleUpgrade}
        >
          {" "}
          Hero
        </a>
      </span>
    </>
  );
};

export default Pro;
