import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequest } from "../../api/request";

export const fetchAsyncProjects = createAsyncThunk(
  "projects/fetchAsyncProjects",
  async (postData) => {
    let param = `/projects?segment=${postData.segment}&apiToken=${postData.apiToken}`;
    const response = await ApiRequest.get(param);
    return response.data;
  }
);

const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    images: [],
    segment: 0,
    searched_text: "all",
    apiToken: undefined,
    loading: false,
    paginate: true,
    notfound: false
  },
  reducers: {
    projectResultReset: (state) => {
      state.images = [];
    },
  },
  extraReducers : builder =>{
    builder.addCase(fetchAsyncProjects.pending, state =>{
      //console.log("fetchAsyncProjects Pending");
      state.loading = true;
    })
    builder.addCase(fetchAsyncProjects.fulfilled, (state,action) =>{
      //console.log("fetchAsyncProjects Successfully");
      state.images.push(...action.payload.data?.projects);
      state.segment = action.payload.data?.segment;
      state.searched_text = action.payload.data?.searched_text;
      state.loading = false;
      state.paginate= action.payload.data?.paginate;
      state.notfound = action.payload.data?.projects?.length === 0 ? true : false
    })
    builder.addCase(fetchAsyncProjects.rejected, (state,action) =>{
      //console.log("fetchAsyncProjects Rejected");
      state.loading = false;
    })
  }
});


export const {
  projectResultReset
} = projectsSlice.actions;

export const getAllProjects = (state) => state.projects.images
export const getSegmant = (state) => state.projects.segment;
export const getSearchedText = (state) => state?.images.searched_text;
export const getLoading = (state) => state.projects.loading;
export const getPaginate = (state) => state.projects.paginate;
export const getNotFound = (state) => state.projects.notfound


export default projectsSlice.reducer;
