export const heartToggler = (e) => {
    if (e.target.classList.contains("icon-heart")) {
        e.target.classList.add("icon-heart-fill");
        e.target.classList.remove("icon-heart");
    } else {
        e.target.classList.add("icon-heart");
        e.target.classList.remove("icon-heart-fill");
    }
};

export const formatDate = (date) => {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':')    
    );
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}