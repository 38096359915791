import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAsyncFavorites, searchFavoritesReset } from "../../redux/reducers/favoritesReducer";
import { Config } from "../../util/config";
import "./style.scss";

const SearchFavorites = () => {
  const [searchString, setSearchString] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setSearchString(searchString);
  }, [searchString]);

  useEffect(() => {
    let postData = {
      segment: 0,
      searched_text: "all",
      type: Config.images['ext'],
    };

    dispatch(searchFavoritesReset());
    dispatch(fetchAsyncFavorites(postData));
  }, [dispatch]);

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      let postData = {
        segment: 0,
        searched_text: searchString,
        type: Config.images['ext'],
      };
      dispatch(fetchAsyncFavorites(postData));
      dispatch(searchFavoritesReset());
    }
  };

  return (
    <>
      <div className="search-container favorites">
        <div className="form-group">
          <input
            className="input"
            type="text"
            placeholder="Search favorites"
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            /* disabled={isNotFound} */
            onKeyDown={(e) => handleSearch(e)}
            value={searchString}
          />
        </div>
      </div>
    </>
  );
};

export default SearchFavorites;
