import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { searchImagesReset } from "../../../redux/reducers/imagesReducer";
import { getCurentUser, logout } from "../../../redux/reducers/userReducer";
import "./style.scss";

const ProfileMenu = ({ isOpened }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getCurentUser);

  const handleSignOut = (e) => {
    e.preventDefault();
    dispatch(logout());
    dispatch(searchImagesReset());
    navigate("/login");
    toast.success("Successfully logout");
  };

  return (
		<>
			{user ? (
				<div className="text-center">
					<div className="dropdown-button-wrap">
						<DropdownButton
							as={ButtonGroup}
							key="up"
							id="dropdown-button-drop-up"
							drop="up"
							variant="secondary"
							title=""
							className=""
						>
							<Dropdown.Item
								eventKey="1"
								target="_blank"
								href="https://drawify.com/dashboard"
							>
								Dashboard
							</Dropdown.Item>
							<Dropdown.Item
								eventKey="2"
								target="_blank"
								href="https://drawify.com/customer/payments"
							>
								Subscription
							</Dropdown.Item>
							<Dropdown.Item
								eventKey="3"
								href="https://drawify.com/contact-us"
								target="_blank"
							>
								Support
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item onClick={handleSignOut} eventKey="4">
								Sign out
							</Dropdown.Item>
						</DropdownButton>
					</div>
				</div>
			) : (
				<div className="text-center">
					<OverlayTrigger
						key="top"
						placement="top"
						overlay={<Tooltip id="tooltip-top">User & Profile</Tooltip>}
					>
						<Link
							to="/login/step-1"
							className="button-icon menu-button icon-user"
							type="button"
						></Link>
					</OverlayTrigger>
				</div>
			)}
		</>
	);
};

export default ProfileMenu;
