import React from "react";
import "./style.scss";

const UpgradeMenu = () => {

  return (
    <div className="upgrade-to-hero">
      <a
        type="button"
        className="w-100 button button-primary"
        rel="noreferrer"
        href={`${process.env.REACT_APP_USER_UPGRADE_TO_HERO_URL}?source=miro`}
        target="_blank"
      >
        Upgrade to Hero
      </a>
      
    </div>
  );
};

export default UpgradeMenu;
