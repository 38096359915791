import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import favoriteAddReducer from "./reducers/favoriteAddReducer";
import favoriteRemoveReducer from "./reducers/favoriteRemoveReducer";
import favoritesReducer from "./reducers/favoritesReducer";
import imagesReducer from "./reducers/imagesReducer";
import projectsReducer from "./reducers/projectsReducer";
import requestReducer from "./reducers/requestReducer";
import uploadsReducer from "./reducers/uploadsReducer";
import userReducer from "./reducers/userReducer";
import projectScenesReducer from "./reducers/projectScenesReducer"

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  //blacklist: ['images', 'favourites', 'request', 'project'],
  whitelist: ['user']
};

const appReducer = combineReducers({
  user: userReducer,
  images: imagesReducer,
  favourites: favoritesReducer,
  favourite_add: favoriteAddReducer,
  favourite_remove: favoriteRemoveReducer,
  projects: projectsReducer,
  project_scenes: projectScenesReducer,
  uploads: uploadsReducer,
  request: requestReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
