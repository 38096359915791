import React from "react";
import { useSelector } from "react-redux";
import { getCurentUser } from "../../../redux/reducers/userReducer";
import Guest from "./guest";
import NotFoundFree from "./not-found-free";
import OnlyForHero from "./only-for-hero";
import "./style.scss";

const NotFound = () => {
  const user = useSelector(getCurentUser);
  const isHeroUser = user?.userPlan === "Hero" ? true : false;

  return (
    <div className="app-container image-results-container">
      {user ? isHeroUser ? <OnlyForHero /> : <NotFoundFree /> : <Guest />} 
    </div>
  );
};

export default NotFound;
