import React from "react";
import { useSelector } from "react-redux";
import ProjectsList from "../../components/ProjectsList";
import SearchProjects from "../../components/SearchInput/SearchProjects";
import Spinner from "../../components/Spinner";
import { getLoading, getNotFound } from "../../redux/reducers/projectsReducer";
import { getCurentUser } from "../../redux/reducers/userReducer";
import Empty from "./Empty";
import Guest from "./Guest";
import Pro from "./Pro";
import "./style.scss";
const Projects = () => {
	const isEmpty = useSelector(getNotFound);
	const isLoadings = useSelector(getLoading);
	const user = useSelector(getCurentUser);

	const userLevel =
		user?.userPlan === "Hero" || user?.userPlan === "Pro" ? "Paid" : "Free";

	return (
		<>
			{user && userLevel === "Paid" && <SearchProjects />}
			{user ? (
				userLevel === "Paid" ? (
					!isEmpty ? (
						<ProjectsList />
					) : (
						<Empty />
					)
				) : (
					<Pro />
				)
			) : (
				<Guest />
			)}
			<Spinner show={isLoadings} />
		</>
	);
};

export default Projects;
