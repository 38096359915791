import React from "react";

const Hero = () => {
 
  return (
    <>
      <span className="p-small">
      
      </span>
    </>
  );
};

export default Hero;
