import React from 'react'
import { Image } from 'react-bootstrap'
import AuthMenu from '../../../components/AuthMenu'
import './style.scss'

const Guest = () => {
  return (
    <div className="app-container auto-height">
      <h4 className="h4 title-h4">Favorite this image</h4>
      <div className="image-place-holder banner-images">
        <Image
          src="/images/favorite-guest.jpg"
          className="img-center"
          height="208"
          width="308"
          alt=""
        />
      </div>
      <p className='p-description'>With a Drawify subscription you can save as many favorite images as you like, for speed and convenience.</p>
  
      <AuthMenu/>
      
    </div>
  )
}

export default Guest