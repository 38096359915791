import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AuthMenu from "../../../components/AuthMenu";

const Guest = () => {
  return (
    <>
      <h4 className="h4 title-h4">There are no images found on Miro</h4>
      <div className="image-place-holder banner-images">
        <LazyLoadImage
          src="/images/no-images.jpg"
          className="img-center"
          height="205"
          width="308"
          alt=""
        />
      </div>
      <p className="p-description">
        Good news! There are results from the rest of Drawify's 12,000+ images
        that match. Sign in or sign up to view them.
      </p>
      <AuthMenu />
    </>
  );
};

export default Guest;
