import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { events } from "../../../api/statistics";
import {
  getCurentUser,
  getLoading,
  postAsyncLogin,
} from "../../../redux/reducers/userReducer";
import { IconEye, IconEyeSlash } from "../../../util/icons";
import { login as loginValidate } from "../../../util/validation";
import "./style.scss";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(getLoading);
  const user = useSelector(getCurentUser);

  const [sEmail, setEmail] = useState("");
  const [sPassword, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [inputType, setInputType] = useState('password');

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  let oUser = {
    email: sEmail,
    password: sPassword,
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  let handleLogin = (e) => {
    e.preventDefault();
    let oResponse = loginValidate(oUser);
    setErrors(oResponse);

    if (Object.keys(oResponse).length === 0) {
      dispatch(postAsyncLogin(oUser)).then((res) => {
        if (res.payload.status) {
          toast.success("Welcome to the Drawify App!");
        } else {
          toast.error(res.payload.message);
          setErrors({
            ath_error: res.payload.message,
          });
        }
      });
    }
  };

  const handleSignUp = () => {
    events("click_register");
  };

  const showPasswordHandler = () => {
    if(inputType === "password"){
      setInputType('text')
    } else{
      setInputType('password')
    }
  };

  return (
    <div className="app-container auto-height login-form">
      <h2 className="h3 title-h3">Sign in</h2>
      <form>
        <div
          className={`${
            errors?.email || errors?.ath_error ? "error" : ""
          } form-group`}
        >
          <input
            className="input"
            name="email"
            type="email"
            id="email"
            autoComplete="email"
            placeholder="Email"
            onKeyDown={handleEnterKey}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div className="status-text">{errors?.email}</div>
        </div>
        <div
          className={`${
            errors?.password || errors?.ath_error ? "error" : ""
          } form-group`}
        >
          <input
            className="input"
            name="password"
            type={inputType}
            id="password"
            onKeyDown={handleEnterKey}
            autoComplete="current-password"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <i onClick={showPasswordHandler} className="eye-icon">
            {inputType === "text" ? <IconEyeSlash /> : <IconEye />}
          </i>
          <div className="status-text">{errors?.password}</div>
          {errors?.ath_error && (
            <div className="status-text">{errors?.ath_error}</div>
          )}
        </div>
      </form>
      <a
        className="p-small link float-right"
        href="https://drawify.com/auth/forgot/password"
        rel="noreferrer"
        target="_blank"
      >
        Forgot password
      </a>
      <button
        type="button"
        className={`${
          isLoading ? "button-loading" : ""
        } w-100 button button-primary `}
        onClick={handleLogin}
      >
        Sign in
      </button>
      <p className="seperator uppercase">or</p>
      <a
        type="button"
        className="w-100 button button-secondary"
        rel="noreferrer"
        href={`${process.env.REACT_APP_USER_SIGN_UP_URL}?source=miro`}
        onClick={handleSignUp}
        target="_blank"
      >
        Sign up
      </a>
    </div>
  );
};

export default Login;
