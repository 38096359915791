import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Spinner from "../../components/Spinner";
import ProjectScenesList from "../../components/ProjectScenesList/index.jsx";
import {
	getLoading,
	projectResultReset,
	fetchAsyncProjectScenes,
	getNotFound,
} from "../../redux/reducers/projectScenesReducer";
import { getCurentUser } from "../../redux/reducers/userReducer";
import Empty from "../Projects/Empty";
import Guest from "../Projects/Guest";
import Pro from "../Projects/Pro";

const ProjectScenes = () => {
	const { state } = useLocation();
	const { projectId } = state;

	const dispatch = useDispatch();

	const user = useSelector(getCurentUser);
	const isEmpty = useSelector(getNotFound);
	const isLoadings = useSelector(getLoading);

	const userLevel =
		user?.userPlan === "Hero" || user?.userPlan === "Pro" ? "Paid" : "Free";

	useEffect(() => {
		const postData = {
			segment: 0,
			projectId,
			apiToken: user.apiToken,
		};

		dispatch(projectResultReset());
		dispatch(fetchAsyncProjectScenes(postData));
	}, [dispatch, user.apiToken, projectId]);

	return (
		<>
			{user ? (
				userLevel === "Paid" ? (
					!isEmpty ? (
						<ProjectScenesList projectId={projectId} />
					) : (
						<Empty />
					)
				) : (
					<Pro />
				)
			) : (
				<Guest />
			)}
			<Spinner show={isLoadings} />
		</>
	);
};

export default ProjectScenes;
