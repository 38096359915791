import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequest } from "../../api/request";

export const postAsyncLogin = createAsyncThunk(
  "user/postAsyncLogin",
  async (postData) => {
    // TODO: ApiRequest | privateRequest |  should be handle based on User
    let param = `?email=${postData.email}&password=${encodeURIComponent(postData.password)}`;
    const response = await ApiRequest.post(`/login${param}`, postData);
    return response.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    loading: false,
    error: false,
  },
  reducers: {
    logout: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(postAsyncLogin.pending, (state) => {
      //console.log("postAsyncRequest Pending");
      state.loading = true;
    });
    builder.addCase(postAsyncLogin.fulfilled, (state, action) => {
      //console.log("postAsyncRequest Successfully");
      state.loading = false;
      if(action.payload.status){
        state.currentUser = action.payload
      }
    });
    builder.addCase(postAsyncLogin.rejected, (state, action) => {
      //console.log("postAsyncRequest Rejected");
      state.loading = false;
    });
  },
});

export const { logout } = userSlice.actions;

export const getLoading = (state) => state.loading;
export const getCurentUser = (state) => state?.user.currentUser;
export const getError = (state) => state.error;

export default userSlice.reducer;
