import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequest } from "../../api/request";

export const fetchAsyncFavorites = createAsyncThunk(
  "favorites/fetchAsyncFavorites",
  async (postData) => {
    const response = await ApiRequest.get(
      `/favorites?segment=${postData.segment}&searched_text=${
        postData.searched_text === "" ? "all" : postData.searched_text
      }&type=${postData.type}`
    );
    return response.data;
  }
);

const favoritesSlice = createSlice({
  name: "favorites",
  initialState: {
    images: [],
    searched_text: "all",
    segment: 0,
    loading: false,
    paginate: true,
    notfound: false,
  },
  reducers: {
    searchFavoritesReset: (state) => {
      state.images = [];
    },
    removeFromFaroviteList: (state, action) => {
      state.images.splice(action.payload, 1);
      if (state.images?.length === 0) {
        state.notfound = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncFavorites.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAsyncFavorites.fulfilled, (state, action) => {
      //console.log("fetchAsyncFavorites Successfully");
      state.images.push(...action.payload.data?.images);
      state.segment = action.payload.data?.segment;
      state.searched_text = action.payload.data?.searched_text;
      state.loading = false;
      state.paginate = action.payload.data?.paginate;
      state.notfound = action.payload.data?.images?.length === 0 ? true : false;
    });
    builder.addCase(fetchAsyncFavorites.rejected, (state, action) => {
      //console.log("fetchAsyncFavorites Rejected");
      state.loading = false;
    });
  },
});

export const { searchFavoritesReset, removeFromFaroviteList } =
  favoritesSlice.actions;

export const getAllFavorites = (state) => state.favourites.images;
export const getSegmant = (state) => state.favourites.segment;
export const getSearchedText = (state) => state?.images.searched_text;
export const getLoading = (state) => state.favourites.loading;
export const getPaginate = (state) => state.favourites.paginate;
export const getNotFound = (state) => state.favourites.notfound;

export default favoritesSlice.reducer;
