import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./Layout";
import Login from "./pages/Auth/Login";
import Step1 from "./pages/Auth/Login/Step1";
import Step2 from "./pages/Auth/Login/Step2";
import Errors from "./pages/Errors";
import Favorites from "./pages/Favorites";
import Help from "./pages/Help";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import ProjectScenes from "./pages/ProjectScenes"
import Request from "./pages/Request";
import Failed from "./pages/Request/Failed";
import Success from "./pages/Request/Success";
import Uploads from "./pages/Uploads";
import "./styles/app.scss";
import { formatDate } from "./util/helper";
import { usage } from "./api/statistics";
import Notification from "./components/Notification";
import { isMobile } from "react-device-detect";

const { board } = window.miro;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Errors />,
    children: [
      {
        path: "/index.html",
        element: <Home />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/favorites",
        element: <Favorites />,
      },
      {
        path: "/projects",
        element: <Projects />,
      },
      {
        path: "/project-scenes",
        element: <ProjectScenes/>
      },
      {
        path: "/uploads",
        element: <Uploads />,
      },
      {
        path: "/help",
        element: <Help />,
      },
      {
        path: "/request",
        element: <Request />,
      },
      {
        path: "/request/success",
        element: <Success />,
      },
      {
        path: "/request/failed",
        element: <Failed />,
      },
      {
        path: "/login/step-1",
        element: <Step1 />,
      },
      {
        path: "/login/step-2",
        element: <Step2 />,
      },

      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/error-t",
        element: <Errors />,
      },
    ],
  },
]);

function App() {
  const drop = async (e) => {
    const { x, y, target } = e;

    if (target instanceof HTMLImageElement) {
      await board.createImage({ x, y, url: target.src });
      let postData = {
        app: "miro",
        app_user_id: localStorage.getItem("miroId"),
        asset_id: target.getAttribute("data-image-id"),
        asset_type: target.getAttribute("data-image-type"),
        datetime: formatDate(new Date()),
      };
      usage(postData);
    }
  };

  useEffect(() => {
    board.ui.on("drop", drop);
  }, []);

  return (
    <>
      {isMobile && <Notification />}
      <RouterProvider router={router} />
    </>
  );
}

export default App;
