import React from "react";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
const Layout = () => {
  return (
    <div className="layout">
      <Header />
      <Outlet />
      <Footer />
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 3000,
          style: {
            marginBottom: "52px",
          },
          success: {
            style: {
              color: "#0f5132",
              backgroundColor: "#d1e7dd",
              borderColor: "#badbcc",
            },
          },
          error: {
            style: {
              color: "#842029",
              backgroundColor: "#f8d7da",
              borderColor: "#f5c2c7",
            },
          },
        }}
      />
    </div>
  );
};

export default Layout;
