import React from "react";
import "./style.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllImages,
  getSegmant,
  fetchAsyncImages,
  getPaginate,
  getLoading,
  getSearchedText
} from "../../redux/reducers/imagesReducer";
import Image from "../Image";
import {Config} from "../../util/config";


const ImagesList = () => {
  const dispatch = useDispatch();

  const aImages = useSelector(getAllImages);
  const iSegment = useSelector(getSegmant);
  const sSearchedTest = useSelector(getSearchedText);
  const isLoadings = useSelector(getLoading);
  const isPaginate = useSelector(getPaginate);

  let imagesList = aImages;
  
  const handleLoadMore = () => {
    let postData = {
      segment: iSegment,
      searched_text:sSearchedTest,
      type: Config.images['ext'],
    };
    dispatch(fetchAsyncImages(postData));
  };

  return (
    <>
      <div className="search-results-container">
        <div className="overflow-container image-results-container">
        <div className="grid">
            {imagesList?.map((image, index) => (
              <Image key={index} index={index} image={image} />
            ))}
          </div>

          {!isLoadings && 
            <div className="load-more-images cs1 ce12">
            <button
              className={`${
                isLoadings ? "button-loading" : ""
              } button button-primary button-small `}
              type="button"
              onClick={() => handleLoadMore()}
              disabled={!isPaginate}
            >
              More images
            </button>
          </div>
          }
        </div>
      </div>
    </>
  );
};

export default ImagesList;
