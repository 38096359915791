
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { searchImagesReset } from '../../redux/reducers/imagesReducer';
import { logout } from '../../redux/reducers/userReducer';
import './style.scss'

const Errors = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSignOut = (e) => {
    e.preventDefault();
    dispatch(logout());
    dispatch(searchImagesReset());
    navigate("/login");
  };
  return (
    <div className="app-container auto-height">
      <h4 className="h4 title-h4">Hmmm..!! Someting Wrong</h4>
      <div className="image-place-holder banner-images">
        <LazyLoadImage
          src="/images/error.jpg"
          className="img-center"
          height="208"
          width="308"
          alt=""
        />
      </div>
      <p className='p-description'>Sorry, this time your request can not be process by our end.</p>
      <Link className='go-back' onClick={handleSignOut}>Go back</Link>
    </div>
  )
}

export default Errors;