import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AuthMenu from "../../../components/AuthMenu";

import "./style.scss";

const Guest = () => {
  return (
    <div className="app-container auto-height">
      <h4 className="h4 title-h4">See your projects</h4>
      <div className="image-place-holder banner-images">
        <LazyLoadImage
          src="/images/project-guest.jpg"
          className="img-center"
          height="208"
          width="308"
          alt=""
        />
      </div>

      <p className="p-description">
        With a Drawify subscription you can see all your projects and even
        create a new project, for speed and convenience
      </p>

      <AuthMenu />
    </div>
  );
};

export default Guest;
