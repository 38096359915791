import React from "react";
import { Image } from "react-bootstrap";
import UpgradeToHero from "../../../components/UpgradeMenu";
import "./style.scss";

const Pro = () => {
  return (
    <div className="app-container auto-height pro-user">
      <h4 className="h4 title-h4">See your projects</h4>
      <div className="image-place-holder banner-images">
        <Image
          src="/images/uploads-guest.jpg"
          className="img-center"
          height="208"
          width="308"
          alt=""
        />
      </div>

      <p className="p-description">
        With a Drawify Hero subscription you can see all your uploads listed
        here, for speed and convenience.
      </p>

      <UpgradeToHero />
    </div>
  );
};

export default Pro;
