import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequest } from "../../api/request";

export const fetchAsyncUploads = createAsyncThunk(
  "uploads/fetchAsyncUploads",
  async (postData) => {
    // TODO : ApiRequest | privateRequest |  should be handle based on User
    const response = await ApiRequest.get(
      `/uploads?segment=${postData.segment}`
    );
    return response.data;
  }
);

const uploadsSlice = createSlice({
  name: "uploads",
  initialState: {
    images: [],
    segment: 0,
    loading: false,
    paginate: true,
    notfound: false
  },
  reducers: {
    projectResultReset: (state) => {
      state.images = [];
    },
  },
  // TODO : Ask Kasun to change images to uploads
  extraReducers : builder =>{
    builder.addCase(fetchAsyncUploads.pending, state =>{
      //console.log("fetchAsyncUploads Pending");
      state.loading = true;
    })
    builder.addCase(fetchAsyncUploads.fulfilled, (state,action) =>{
      //console.log("fetchAsyncUploads Successfully");
      state.images.push(...action.payload.data?.images);
      state.segment = action.payload.data?.segment;
      state.loading = false;
      state.paginate= action.payload.data?.paginate;
      state.notfound = action.payload.data?.images?.length === 0 ? true : false
    })
    builder.addCase(fetchAsyncUploads.rejected, (state,action) =>{
      //console.log("fetchAsyncUploads Rejected");
      state.loading = false;
    })
  }
});


export const {
  projectResultReset
} = uploadsSlice.actions;

export const getAllUploads = (state) => state.uploads.images
export const getSegmant = (state) => state.uploads.segment;
export const getLoading = (state) => state.uploads.loading;
export const getPaginate = (state) => state.uploads.paginate;
export const getNotFound = (state) => state.uploads.notfound


export default uploadsSlice.reducer;
