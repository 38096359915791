import React from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postAsyncFavoriteAdd } from "../../redux/reducers/favoriteAddReducer";
import { getCurentUser } from "../../redux/reducers/userReducer";
import { formatDate, heartToggler } from "../../util/helper";

import { Image as BSImage } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { usage } from "../../api/statistics";
import { getSearchedText } from "../../redux/reducers/imagesReducer";

const { board } = window.miro;

const Image = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sSearchedText = useSelector(getSearchedText);

  const user = useSelector(getCurentUser);

  const addToFavorite = (e, image) => {
    if (user) {
      dispatch(postAsyncFavoriteAdd({ drawing_id: image.id })).then((res) => {
        if (res.payload.status) {
          toast.success(res.payload.message);
          heartToggler(e);
        } else {
          toast.success(res.payload.message);
        }
      });
    } else {
      navigate("/favorites");
    }
  };

  const clickEventHandler = async (e) => {
    if (isMobile) {
      let target = e.target;
      let postData = {
        app: "miro",
        app_user_id: localStorage.getItem("miroId"),
        asset_id: target.getAttribute("data-image-id"),
        asset_type: target.getAttribute("data-image-type"),
        current_search_word: sSearchedText,
        datetime: formatDate(new Date()),
      };
      await board.createImage({ x: 0, y: 0, url: e.target.src });
      usage(postData);
    }
  };

  const { index, image } = props;
  return (
    <div
      className={
        index % 2 === 0
          ? "cs1 ce6 image-placeholder"
          : "cs7 ce12 image-placeholder"
      }
      tabIndex={index}
      key={index}
    >
      <div
        className="favorite-icon "
        onClick={(e) => addToFavorite(e, image)}
        data-id={image.id}
      >
        <div
          className={`${
            image.favorite ? "icon-heart-fill" : "icon-heart"
          } icon`}
        ></div>
      </div>
      <BSImage
        src={image.url}
        className="miro-draggable draggable-item"
        data-url={image.url}
        alt="Drag and Drop"
        title="Drag and Drop"
        onClick={(e) => clickEventHandler(e, image)}
        key={index}
        data-image-id={image.id}
        data-image-type={image.type}
        //draggable={false}
      />
    </div>
  );
};

export default Image;
