import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAsyncFavorites,
  getAllFavorites,
  getLoading,
  getPaginate,
  getSearchedText,
  getSegmant,
} from "../../redux/reducers/favoritesReducer";
import './style.scss'
import FavoriteImage from "../FavoriteImage";
import { Config } from "../../util/config";

const FavoritesLists = () => {

  const dispatch = useDispatch();

  const aFavorites = useSelector(getAllFavorites);
  const iSegment = useSelector(getSegmant);
  const sSearchedTest = useSelector(getSearchedText);
  const isLoadings = useSelector(getLoading);
  const isPaginate = useSelector(getPaginate);

  let imagesList = aFavorites;

  const handleLoadMore = () => {
    let postData = {
      segment: iSegment,
      searched_text: sSearchedTest,
      type: Config.images['ext'],
    };
    dispatch(fetchAsyncFavorites(postData));
  };

  return (
    <>
      <div className="search-results-container">
        <div className="overflow-container favorite-results-container">
          <div className="grid">
            {imagesList?.map((image, index) => (
              <FavoriteImage key={index} index={index} image={image} />
            ))}
          </div>
          {!isLoadings && (
            <div className="load-more-images cs1 ce12">
              <button
                className={`${
                  isLoadings ? "button-loading" : ""
                } button button-primary button-small `}
                type="button"
                onClick={() => handleLoadMore()}
                disabled={!isPaginate}
              >
                More favorites
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FavoritesLists;
