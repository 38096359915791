import React from "react";
import "./style.scss";
import { toast } from "react-hot-toast";

const Notification = () => {
  return (
    <>
      {toast.custom(
        (t) => (
          <div className="alert-col ">
            <div className="alert alert-primary">
            To add an image to your canvas, tap on it. You can find images in the center.
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        ),
        { duration: 50000 }
      )}
    </>
  );
};

export default Notification;
