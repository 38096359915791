import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAsyncUploads,
  getAllUploads,
  getLoading,
  getPaginate,
  getSegmant,
  projectResultReset,
} from "../../redux/reducers/uploadsReducer";
import './style.scss'
import UploadImage from "../UploadImage";
import NewUploadBtn from "../NewUploadBtn";

const UploadsList = () => {
  const dispatch = useDispatch();

  const aUploads = useSelector(getAllUploads);
  const iSegment = useSelector(getSegmant);
  const isLoadings = useSelector(getLoading);
  const isPaginate = useSelector(getPaginate);

  useEffect(() => {
    let postData = {
      segment: 0,
    };

    dispatch(projectResultReset());
    dispatch(fetchAsyncUploads(postData));
  }, [dispatch]);

  const handleLoadMore = () => {
    let postData = {
      segment: iSegment,
    };
    dispatch(fetchAsyncUploads(postData));
  };

  let imagesList = aUploads;

  return (
    <>
    <div className="search-results-container">
      <div className="overflow-container upload-results-container">
        <div className="grid">
          <div className='cs1 ce6 image-placeholder'>
            <NewUploadBtn/>
            <span className="name">&nbsp;</span>
          </div>
          {imagesList?.map((image, index) => (
            <UploadImage key={index} index={index} image={image} />
          ))}
        </div>
        {!isLoadings && (
          <div className="load-more-images cs1 ce12">
            <button
              className={`${
                isLoadings ? "button-loading" : ""
              } button button-primary button-small `}
              type="button"
              onClick={() => handleLoadMore()}
              disabled={!isPaginate}
            >
              More uploads
            </button>
          </div>
        )}
      </div>
    </div>
  </>
  );
};

export default UploadsList;
