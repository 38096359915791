import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCurentUser } from "../../redux/reducers/userReducer";
import ProfileMenu from "./ProfileMenu";
import "./style.scss";
import Free from "./Upgrage/Free";
import Guest from "./Upgrage/Guest";
import Hero from "./Upgrage/Hero";
import Pro from "./Upgrage/Pro";

const Footer = () => {
  const user = useSelector(getCurentUser);

  return (
    <>
      <div className="menu-footer">
        <div className="grid w-100">
          <div className="cs1 ce6 text-left">
          {user && <span className={`tag mx-2 px-2 ${user?.userPlan?.toLowerCase()}`}>{user?.userPlan}</span> }
            {user ? (
              user?.userPlan === "Free" ? (
                <Free />
              ) : user?.userPlan === "Pro" ? (
                <Pro />
              ) : (
                <Hero/>
              )
            ) : (
              <Guest />
            )}
          </div>
          <div className="cs7 ce12">
            <div className="menu-footer-actions">
              <div className="text-center">
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-'bottom'`}>Request an image</Tooltip>
                  }
                >
                  <Link
                    to="/request"
                    className="button-icon menu-button icon-request"
                    type="button"
                  ></Link>
                </OverlayTrigger>
              </div>
              <div className="text-center">
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Help & FAQ</Tooltip>}
                >
                  <Link
                    to="/help"
                    className="button-icon menu-button icon-help-question"
                    type="button"
                  ></Link>
                </OverlayTrigger>
              </div>
              <ProfileMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
