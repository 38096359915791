import React from "react";
import "./style.scss";

function NewUploadBtn() {
  return (
    <>
    <a
      className="new-upload"
      href={`${process.env.REACT_APP_DRAWIFY_DOMAIN}/customer/dashboard`}
      rel="noreferrer"
      target="_blank"
    >
      <span className="icon icon-upload"></span>
    </a>
    </>
    
  );
}

export default NewUploadBtn;
