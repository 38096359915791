export const login = (oUser) => {
  let errors = {};

  if (!oUser.email) {
    errors["email"] = "Email required";
  }

  if (typeof oUser.email !== "undefined") {
    //regular expression for email validation
    var pattern = new RegExp(
      /^[+_a-z0-9-]+(\.[+_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.(?!web)([a-z0-9]){2,10})$/
    );
    if (!pattern.test(oUser.email)) {
      errors["email"] = "Email invalid";
    }
  }

  if (!oUser.password) {
    errors["password"] = "Password required";
  }

  return errors;
};

export const request = () => {
};
