import React from "react";
import { Image } from "react-bootstrap";
import AuthMenu from "../../../components/AuthMenu";
import "./style.scss";

const Guest = () => {
  return (
    <div className="app-container auto-height">
      <h4 className="h4 title-h4">Request images</h4>
      <div className="image-place-holder banner-images">
        <Image
          src="/images/request-image.jpg"
          className="img-center"
          height="208"
          width="308"
          alt=""
        />
      </div>

      <p className="p-description">
        You can request an image to be drawn for you. Sign up as a Drawify Hero
        to send your image request.
      </p>

      <AuthMenu />
    </div>
  );
};

export default Guest;
