import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthMenu from "../../../../components/AuthMenu";
import { getCurentUser } from "../../../../redux/reducers/userReducer";
import "./style.scss";

const Step1 = () => {

  const user = useSelector(getCurentUser);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  },[user,navigate]); 
  return (
    <div className="app-container auto-height">
      <h4 className="h4 title-h4">
        Power up your team's collaboration with Drawify
      </h4>
      <div className="image-place-holder banner-images">
        <LazyLoadImage
          src="/images/onboarding-step-1.jpg"
          className="img-center"
          height="208"
          width="308"
          alt=""
        />
      </div>
      <p className="p-description">
        Access and customise over 12,000 images and templates, and save your
        favorites for extra speed and convenience
      </p>
      <ul className="login-steps">
        <li>
          <Link className="next circle active" href="/login/step-1"></Link>
        </li>
        <li>
          <Link className="next circle" to="/login/step-2"></Link>
        </li>
        <li>
          <Link className="next circle" to="/login"></Link>
        </li>
      </ul>
      <AuthMenu/>
    </div>
  );
};

export default Step1;
