import React from "react";
import { Image } from "react-bootstrap";
import { usage } from "../../api/statistics";
import { formatDate } from "../../util/helper";
import "./style.scss";
import { isMobile } from "react-device-detect";

const { board } = window.miro;

const UploadImage = (props) => {
  const { index, image } = props;

  const clickEventHandler = async (e) => {
    if (isMobile) {
      let target = e.target;
      let postData = {
        app: "miro",
        app_user_id: localStorage.getItem("miroId"),
        asset_id: target.getAttribute("data-image-id"),
        asset_type: target.getAttribute("data-image-type"),
        current_search_word: "",
        datetime: formatDate(new Date()),
      };
      await board.createImage({ x: 0, y: 0, url: e.target.src });
      usage(postData);
    }
  };

  return (
    <div
      className={
        index === 0
          ? "cs7 ce12 image-placeholder"
          : index % 2 === 0
          ? "cs7 ce12 image-placeholder"
          : "cs1 ce6 image-placeholder"
      }
      tabIndex={index}
      key={index}
    >
      <div className="upload-image-placeholder">
        <Image
          src={image.url}
          className="miro-draggable draggable-item"
          data-url={image.url}
          alt="Drag and Drop"
          title="Drag and Drop"
          onClick={(e) => clickEventHandler(e, image)}
          key={index}
          data-image-id={image.id}
          data-image-type={image.type}
          onError={(e) => (e.target.src = "/images/placeholder-150x150.jpg")}
        />
      </div>
      <span className="name">{image.name}</span>
    </div>
  );
};

export default UploadImage;
