import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./style.scss";

const Help = () => {
	return (
		<div className="app-container auto-height">
			<h4 className="h4 title-h4">FAQ</h4>

			<p className="text-center">
				Here are some of the answers to the questions that we get the most.
				Don't worry we are here to help you get started.
			</p>
			<Accordion>
				<Accordion.Item eventKey="0">
					<Accordion.Header>What is Drawify?</Accordion.Header>
					<Accordion.Body>
						<p>
							Drawify is a platform that helps you translate complex information
							into eye-catching visuals (without doing any drawing yourself)
							using images hand-drawn by a community of visual storytellers from
							around the world.
						</p>
						<p>
							Whether starting with a blank canvas or with a template, you can
							use Drawify to rapidly compose text and images together as a
							visual story, for presentations, infographics, storyboards, rich
							pictures… just about anything you need!
						</p>
						<p>
							Or, you can add Drawify images (as well as any compositions you've
							created) to your boards, right from within Miro, through the
							Drawify Miro App.
						</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="1">
					<Accordion.Header>
						How do I use the Drawify Miro App?
					</Accordion.Header>
					<Accordion.Body>
						<p>To add the Drawify Miro App to your Miro experience:</p>
						<ol>
							<li>
								Click on the Explore Apps and Integrations icon, located in the
								top icon bar in Miro
							</li>
							<li>
								In the pop-up that appears, search for Drawify, then click on
								the Drawify Images search result panel that appears
							</li>
							<li>
								In the Drawify Images page that appears, click the Install
								button
							</li>
						</ol>
						<p>
							You can then drag the Drawify icon from your list of installed
							Apps into your main toolbar, for more convenient access to Drawify
							images.
						</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="2">
					<Accordion.Header>
						What benefits does the Drawify App offer?
					</Accordion.Header>
					<Accordion.Body>
						<ol>
							<li>
								<strong>Search and browse 1000s of images</strong> - search by
								keyword, and then drag an image you want to use from the app
								into your board. The paid plans give you access to 1000s more
								images.
							</li>
							<li>
								<strong>Favorite images</strong> - click the heart icon on any
								images you like, and then access your favorites for speed and
								convenience
							</li>
							<li>
								<strong>Access your Drawify projects</strong> - browse and drag
								any visual compositions you have created in Drawify into your
								Miro board
							</li>
						</ol>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="3">
					<Accordion.Header>
						What sorts of images does Drawify have?
					</Accordion.Header>
					<Accordion.Body>
						<p>
							With the Drawify Miro App, you have access to 750 images. If you
							have Drawify Free account you have access 3000 images or all
							images on either the Pro or Hero plans.
						</p>
						<p>
							The images cover a wide range of topics, including faces and
							figures, objects and symbols, maps, flora and fauna, scenes, and
							abstract backgrounds.
						</p>
						<p>
							There are also lots of frames, lines, arrows, and connectors,
							speech balloons, and metaphors.
						</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="4">
					<Accordion.Header>Can I customize the images?</Accordion.Header>
					<Accordion.Body>
						<p>
							Yes, you can customize the colors of each image, and arrange
							several images to create compositions of larger images, via the
							Drawify App.
						</p>
						<p>
							To access the Drawify App, go to{" "}
							<a href="https://drawify.com" target="_blank" rel="noreferrer">
								https://drawify.com
							</a>
						</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="5">
					<Accordion.Header>
						What sorts of templates does Drawify have?
					</Accordion.Header>
					<Accordion.Body>
						<p>
							Drawify is made to help you explain complex information and
							connect with your audience, and to help teams work together
							better. The templates are in categories such as:
						</p>
						<ul>
							<li>Presentations</li>
							<li>Meetings</li>
							<li>Strategy and planning</li>
							<li>Research and ideation</li>
							<li>Agile management</li>
							<li>Infographics</li>
						</ul>
						<p>
							…and more! Some are available with the Free plan, but more are
							available with the Pro and Hero plans. Access the templates via
							the Drawify App, at{" "}
							<a href="https://drawify.com" target="_blank" rel="noreferrer">
								https://drawify.com
							</a>
						</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="6">
					<Accordion.Header>
						Can I use Drawify images for commercial purposes?
					</Accordion.Header>
					<Accordion.Body>
						<p>
							Yes, you can. Whatever Drawify account you have, your account
							includes a royalty-free license to use and re-use the images,
							individually or in combination with others. For more information,
							please read our{" "}
							<a
								href="https://drawify.com/files/terms-and-conditions.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Terms and Conditions
							</a>
						</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="7">
					<Accordion.Header>
						What do I get in the different paid plans?
					</Accordion.Header>
					<Accordion.Body>
						<p>
							The <strong>Drawify Free plan</strong> has everything to
							jump-start your visual storytelling journey: access to 3,000
							images with a royalty free license, watermark-free, available
							through the Drawify Miro App. You can customize and use these
							images in your Drawify projects, as well as some essential
							templates.
						</p>
						<p>
							The <strong>Pro plan</strong> helps you grow your visual
							storytelling powers, with access to the complete library of over
							12,000 customizable images, more templates, plus the ability to
							upload your own images and fonts from other sources, to use in
							your Drawify projects.
						</p>
						<p>
							The <strong>Hero plan</strong> helps you scale your visual
							storytelling impact, with workflow accelerator features like more
							templates, and the ability to save customized color palettes and
							compositions. On top of this, you get free access to monthly
							online workshops to improve your storytelling skills.
						</p>
						<p>
							For the Hero plan, you can choose between a monthly or yearly
							subscription; the yearly version allows you to save up to 33%. The
							Pro plan is only available as a yearly subscription.
						</p>
						<p>
							For enterprise and educational organisations, we offer licensing
							adapted to your specific needs. Please contact us on{" "}
							<strong>info@drawify.com</strong> to find out more
						</p>
						<p>
							For the full set of features and a feature comparison table, go to{" "}
							<a
								href="https://drawify.com/payment/plans"
								target="_blank"
								rel="noreferrer"
							>
								https://drawify.com/payment/plans
							</a>
						</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="8">
					<Accordion.Header>Who draws the images on Drawify?</Accordion.Header>
					<Accordion.Body>
						<p>
							All the Drawify images you see are the hard work of a great
							community of talented visual storytellers. They are not just
							illustrators; they are strategically-thinking visual facilitators,
							applying their craft in all sorts of businesses, large and small.
							They understand your world, your language, and your domain.
						</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="9">
					<Accordion.Header>
						What if I can't find the image I'm looking for?
					</Accordion.Header>
					<Accordion.Body>
						<p>
							If you can't can't find anything that matches your search, click
							the Request image icon at the bottom of the Drawify Miro app (Hero
							plan only).{" "}
						</p>
						<p>
							This enables you to send a request for whatever image you'd like.
							One or more Drawifiers will then get drawing, and then images
							matching your request will be available on Drawify (and via the
							Drawify Miro App) within 48 hours.
						</p>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>

			{/*  <div className="btn">
        <button onClick={handleClearCache}>Cache Clear</button>
      </div> */}
		</div>
	);
};

export default Help;
