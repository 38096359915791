import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import UpgradeMenu from "../../../components/UpgradeMenu";

const NotFoundFree = () => {
  return (
    <>
      <h4 className="h4 title-h4">Request an image</h4>
      <div className="image-place-holder banner-images">
        <LazyLoadImage
          src="/images/no-images.jpg"
          className="img-center"
          height="205"
          width="308"
          alt=""
        />
      </div>
      <p className="p-description">
        There are no images found on Miro. But you can request an image be drawn
        for you. If you have a <span>Hero Drawify subscription</span>, upgrade
        to send your request.
      </p>
      <UpgradeMenu />
    </>
  );
};

export default NotFoundFree;
