import React from "react";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import UploadsList from "../../components/UploadsList";
import { getLoading, getNotFound } from "../../redux/reducers/uploadsReducer";
import { getCurentUser } from "../../redux/reducers/userReducer";
import Empty from "./Empty";
import Guest from "./Guest";
import Pro from "./Pro";
import "./style.scss";

const Uploads = () => {
  const isEmpty = useSelector(getNotFound);
  const isLoadings = useSelector(getLoading);
  const user = useSelector(getCurentUser);
  const userLevel = (user?.userPlan === "Hero" || user?.userPlan === "Pro" ) ? "Paid" : "Free";
  return (
    <>
      {user ? (
        userLevel === "Paid" ? (
          !isEmpty ? (
            <UploadsList />
          ) : (
            <Empty />
          )
        ) : (
          <Pro />
        )
      ) : (
        <Guest />
      )}
      <Spinner show={isLoadings} />
    </>
  );
};

export default Uploads;
