import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";

const Header = () => {
	const location = useLocation();
	const { pathname } = location;
	const to = pathname.split("/")[1];

	console.log(to)

	return (
		<>
			<div className="menu-header">
				<div className="text-center">
					<OverlayTrigger
						key="bottom"
						placement="bottom"
						overlay={<Tooltip id={`tooltip-'bottom'`}>Images</Tooltip>}
					>
						<Link
							to="/"
							className={`${
								to === "/" || to === "" ? "active" : ""
							} button-icon menu-button icon-image`}
							type="button"
						></Link>
					</OverlayTrigger>
				</div>
				<div className="text-center">
					<OverlayTrigger
						key="bottom"
						placement="bottom"
						overlay={<Tooltip id={`tooltip-'bottom'`}>Favorites</Tooltip>}
					>
						<Link
							to="/favorites"
							className={`${
								to === "favorites" ? "active" : ""
							} button-icon menu-button icon-heart`}
							type="button"
						></Link>
					</OverlayTrigger>
				</div>
				<div className="text-center">
					<OverlayTrigger
						key="bottom"
						placement="bottom"
						overlay={<Tooltip id={`tooltip-'bottom'`}>Projects</Tooltip>}
					>
						<Link
							to="/projects"
							className={`${
								(to === "projects" || to === "project-scenes") ? "active" : ""
							} button-icon menu-button icon-folder`}
							type="button"
						></Link>
					</OverlayTrigger>
				</div>
				<div id="uploads-tab-button" className="text-center">
					<OverlayTrigger
						key="bottom"
						placement="bottom"
						overlay={<Tooltip id={`tooltip-'bottom'`}>Uploads</Tooltip>}
					>
						<Link
							to="/uploads"
							className={`${
								to === "uploads" ? "active" : ""
							} button-icon menu-button icon-upload`}
							type="button"
						></Link>
					</OverlayTrigger>
				</div>
			</div>
		</>
	);
};

export default Header;
