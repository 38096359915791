import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const RequestImageMenu = () => {

  return (
    <div className="auth-guest-menu">
      <Link
        to="/request"
        type="button"
        className="w-100 button button-primary"
      >
        Request image
      </Link>
    </div>
  );
};

export default RequestImageMenu;
