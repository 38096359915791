import { privateRequest, publicRequest } from "../../util/request";
import { store } from "../../redux/store";
import { formatDate } from "../../util/helper";

export const usage = async (postData) => {
  try {
    const state = store.getState();
    const user = state?.user?.currentUser;
    if (user) {
      await privateRequest.post("/app/stats/usage", postData);
    } else {
      await publicRequest.post("/app/stats/usage", postData);
    }
  } catch (error) {
  }
};

export const events = async (sEvent) => {
  let postData = {
    app: "miro",
    app_user_id: localStorage.getItem("miroId"),
    event: sEvent,
    datetime: formatDate(new Date()),
  };
  const state = store.getState();
  const user = state?.user?.currentUser;
  try {
    if (user) {
      await privateRequest.post("/app/stats/events", postData);
    } else {
      await publicRequest.post("/app/stats/events", postData);
    }
  } catch (error) {
  }
};
