import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getSegmant,
	getLoading,
	getPaginate,
	getAllProjects,
	fetchAsyncProjects,
	getSearchedText,
} from "../../redux/reducers/projectsReducer";
import { getCurentUser } from "../../redux/reducers/userReducer";
import NewProjectBtn from "../NewProjectBtn";
import ProjectImage from "../ProjectImage";
import "./style.scss";

const ProjectsList = () => {
	const dispatch = useDispatch();

	const oUser = useSelector(getCurentUser);
	const aProjects = useSelector(getAllProjects);
	const iSegment = useSelector(getSegmant);
	const sSearchedTest = useSelector(getSearchedText);
	const isLoadings = useSelector(getLoading);
	const isPaginate = useSelector(getPaginate);

	let imagesList = aProjects;

	const handleLoadMore = () => {
		let postData = {
			segment: iSegment,
			apiToken: oUser.apiToken,
			searched_text: sSearchedTest,
		};
		dispatch(fetchAsyncProjects(postData));
	};

	return (
		<>
			<div className="search-results-container">
				<div className="overflow-container project-results-container">
					<div className="grid">
						<div className="cs1 ce6" id="new-project-button">
							<div className="image-placeholder">
								<NewProjectBtn />
							</div>
							<span className="name">&nbsp;</span>
						</div>
						{imagesList?.map((image, index) => (
							<ProjectImage key={index} index={index + 1} image={image} />
						))}
					</div>
					{!isLoadings && (
						<div className="load-more-images cs1 ce12">
							<button
								className={`${
									isLoadings ? "button-loading" : ""
								} button button-primary button-small `}
								type="button"
								onClick={() => handleLoadMore()}
								disabled={!isPaginate}
							>
								More projects
							</button>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default ProjectsList;
